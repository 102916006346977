import React, { useState, useEffect } from 'react';
import './Header.css'; // Make sure to create a CSS file for styling
import Svg from "./assets/Logo_Circle_Newspaper_White.svg";
// import Img from "./assets/logo512.png";
import './Header.css';


const Header = ({ selectedType, handleElementClick }) => {
  const [isSticky, setIsSticky] = useState(false);

  function scrollFunction() {
    if (document.body.scrollTop > 93 || document.documentElement.scrollTop > 93) {
      document.getElementById("header").style.padding = "10px 10px";
      document.getElementById("logo").style.hight = "23px";
    } else {
      document.getElementById("header").style.padding = "32px 10px";
      document.getElementById("logo").style.hight = "29px";
    }
  }


  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
      window.onscroll = function () { scrollFunction() };


    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  function handleLogoClick() {
    console.log("handle logo click")
    window.scrollTo(0, 0);
  }

  const today = new Date();
  const date = (today.getMonth() + 1) + '.' + today.getDate() + '.' + today.getFullYear().toString().slice(-2);

  return (
    <div className="section">
      <div className="header" onClick={() => handleLogoClick()} id="header">
        <img className="logotemp1Icon" id="logo" alt="" src={Svg} />
        {/* <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
              
              <circle cx="50" cy="50" r="20" fill="white" />
          </svg> */}
      </div>
      <div className="heroSection">
        <div className="unbiased">Circle</div>
        <div className="allTheNews"> {date} Newspaper</div>
      </div>
      <div className="header1">
        <div className="categories">
          <div className="largeNavbutton" onClick={() => handleElementClick("latestLATEST")} style={{ backgroundColor: selectedType === "latestLATEST" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">LATEST</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestSPORTS")} style={{ backgroundColor: selectedType === "latestSPORTS" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">SPORTS</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestWORLD")} style={{ backgroundColor: selectedType === "latestWORLD" ? "grey" : "#f3f3f3" }}>
            <b className="navtext">WORLD</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestPOLITICS")} style={{ backgroundColor: selectedType === "latestPOLITICS" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">politics</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestBUSINESS")} style={{ backgroundColor: selectedType === "latestBUSINESS" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">Business</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestTECH")} style={{ backgroundColor: selectedType === "latestTECH" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">Tech</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestSCIENCE")} style={{ backgroundColor: selectedType === "latestSCIENCE" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">Science</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestENTERTAINMENT")} style={{ backgroundColor: selectedType === "latestENTERTAINMENT" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">Entertainment</b>
          </div>
          <div className="largeNavbutton" onClick={() => handleElementClick("latestTRAVEL")} style={{ backgroundColor: selectedType === "latestTRAVEL" ? "grey" : "#f3f3f3" }}>
            <b className="navtex">Travel</b>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Header;
