// import React from 'react';
// import React, { useState, useEffect } from 'react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Post from './Post';
const Feed = ({posts, handlePostClick}) => {
    console.log("Posts: ", posts.length, posts.slice(0,9).length);
    let paddingVal = "100px"
    if (window.innerWidth < 900) {
        paddingVal = "20px"
    }

    const [visiblePosts, setVisiblePosts] = useState([]);
    const [currentCount, setCurrentCount] = useState(0);
    const increment = 9; // Number of posts to load each time
    const observer = useRef();

    useEffect(() => {
        setVisiblePosts(posts.slice(0, 9));
        setCurrentCount(9);
    }, [posts]);

    const loadMorePosts = useCallback(() => {
        const newCount = currentCount + increment;
        const newPosts = posts.slice(0, newCount);
        setVisiblePosts(newPosts);
        setCurrentCount(newCount);
    }, [currentCount, posts]);

    const lastPostElementRef = useCallback(
        node => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && currentCount < posts.length) {
                loadMorePosts();
                }
            });
            if (node) observer.current.observe(node);
        },
        [loadMorePosts, currentCount, posts.length]
    );

    return (
        // <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 800: 2, 1000: 3 }}>
        //     <Masonry className='masonry' style={{ padding: paddingVal }} gutter="40px">
        //         {posts.map(post => (
        //             <Post key={post.id} id={post.id} image={post.image} text={post.text} summary={post.summary} handler={handlePostClick} label={post.label} />
        //         ))}
        //     </Masonry>
        // </ResponsiveMasonry>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 800: 2, 1000: 3 }}>
      <Masonry className="masonry" style={{ padding: paddingVal }} gutter="40px">
        {visiblePosts.map((post, index) => {
          if (index === visiblePosts.length - 1) {
            return (
              <div ref={lastPostElementRef} key={post.id}>
                <Post
                  id={post.id}
                  image={post.image}
                  text={post.text}
                  summary={post.summary}
                  handler={handlePostClick}
                  label={post.label}
                />
              </div>
            );
          } else {
            return (
              <Post
                key={post.id}
                id={post.id}
                image={post.image}
                text={post.text}
                summary={post.summary}
                handler={handlePostClick}
                label={post.label}
              />
            );
          }
        })}
      </Masonry>
    </ResponsiveMasonry>
    );
};

export default Feed;