import React from "react";
import "./NewscardRelated.css";
import { useState, useEffect } from "react";
import { navigate } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';

export const NewscardRelated = ({ className, title, category, story_id, img_url }) => {
    // const imageURL = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRratY6b5JzFXRWmdslP9qOpmqX65sn5_LXQQ&s"
    // const category = "ENTERTAINMENT";
    // const title = "Chemicals from East Palestine derailment spread to 16 US states"
    const navigate = useNavigate();
    const [imageError, setImageError] = React.useState(false);

    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        const timestamp = new Date().getTime();
        // setImageUrl(`${img_url}?t=${timestamp}`);
        setImageUrl(`${img_url}`);
      }, [img_url]);

    function handleClick() {
        // push screen 
        console.log("navigate to article", story_id);

        navigate(`/article/${story_id}`);
    }
    return (
        <div className={`newscard-related ${className}`} onClick={handleClick} >
            
                {/* <img alt="" src={img_url} /> */}
                {imageUrl && !imageError ? (
                    
                    <div className="hero-related" >
                        <img 
                        src={imageUrl} 
                        alt="Article" 
                        onError={() => setImageError(true)} 
                        />
                    </div>

                ) : null}
            
            <div className="infocard">
                <div className="category-container">
                    <div className="category">{category}</div>
                    <div className="underline" />
                </div>
                <div className="card-copy-container">
                    <p className="headline-related">{title}</p>
                </div>
            </div>
        </div>
    );
};

export default NewscardRelated;