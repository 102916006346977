import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import "./Frame.css";
import ArrowSVG from "./assets/dropdown.svg";

export const Frame = ({ factList }) => {

    const [isActive, setIsActive] = useState(false);
    const contentRef = useRef(null);

    const toggleActive = () => {
        console.log('clicked');
        setIsActive(!isActive);
    };

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = isActive ? `${contentRef.current.scrollHeight}px` : '0';
        } else {
            console.log('contentRef is null');

        }
    }, [isActive]);

    const iconStyle = {
        transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
    };

    return (
        <div className="accordion">
            <button className="accordion-btn" onClick={toggleActive}>
                <span className="accordion-text">Article Notes</span>
                <img className="accordion-icon" src={ArrowSVG} alt="arrow" />
            </button>
            <div className="accordion-content" ref={contentRef}>
                <div className="list-wrapper">
                    <ul className="list">
                        {factList.map((fact, index) => (
                            <li key={index} className="list-item">
                                <span className="fact">{fact}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Frame;