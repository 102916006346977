import React from 'react';
import './Post.css';
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const Post = ({ id, image, text, summary, handler, label }) => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = React.useState(false);

  useEffect(() => {
    const timestamp = new Date().getTime();
    // setImageUrl(`${image}?t=${timestamp}>i=${id}`);
    setImageUrl(`${image}`)
  }, [image]);

  const handleButtonClick = () => {
    console.log("id TILE", id)
    handler(id);
  }
  return (
    <div className="news-module-NP" onClick={() => handleButtonClick()}>

      {/* <img className="image" alt="" src={imageUrl} /> */}
      {imageUrl && !imageError ? (
        <img
          className="image"
          src={imageUrl}
          alt="Article"
          onError={() => setImageError(true)}
        />

      ) : null}
      <div className="infocard">
        <div className="category-container">
          <div className="category">{label}</div>
          <div className="frame" />
        </div>
        <div className="copy-container" >
          <p className="headline">{text}</p>
          <p className="summary">
            {summary}
          </p>
        </div>
      </div>
    </div>
  );
};




export default Post;
