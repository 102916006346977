import Header from './Header';
import { useState, useRef, useEffect } from 'react';
import Post from './Post';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Feed from './Feed';

const SELECTEDTYPESTR = "selectedType"
const POSTSSTR = "posts"
const SCROLLPOSITIONSTR = "scrollPosition"
const LASTVISITTIMESTR = "lastVisitTime"
const LATESTLATEST = "latestLATEST"
const LATESTSPORTS = "latestSPORTS"
const LATESTWORLD = "latestWORLD"
const LATESTPOLITICS = "latestPOLITICS"
const LATESTBUSINESS = "latestBUSINESS"
const LATESTTECH = "latestTECH"
const LATESTSCIENCE = "latestSCIENCE"
const LATESTHEALTH = "latestHEALTH"
const LATESTARTS = "latestARTS"
const LATESTFOOD = "latestFOOD"
const LATESTFASHION = "latestFASHION"
const LATESTTRAVEL = "latestTRAVEL"
const LATESTENTERTAINMENT = "latestENTERTAINMENT"
const LATESTOPINION = "latestOPINION"
const LATESTBOOKS = "latestBOOKS"
const LATESTMAGAZINE = "latestMAGAZINE"
const LATESTPODCASTS = "latestPODCASTS"
const LATESTVIDEO = "latestVIDEO"
const LATESTAUDIO = "latestAUDIO"
const LATESTPHOTOGRAPHY = "latestPHOTOGRAPHY"


function LandingPage() {
  const currentTime = new Date().getTime();
  let lastVisitTime = localStorage.getItem(LASTVISITTIMESTR);
  if (lastVisitTime == null) {
    localStorage.setItem(LASTVISITTIMESTR, currentTime.toString());
    localStorage.setItem(SELECTEDTYPESTR, "");
    localStorage.setItem(POSTSSTR, JSON.stringify([]));
    localStorage.setItem(SCROLLPOSITIONSTR, 0);
  } else {
    // Calculate the difference between the current time and the stored timestamp
    const timeDifference = (currentTime - new Date(parseInt(lastVisitTime))) / 1000 / 60; // in minutes

    // If the difference is more than 10 minutes, clear the local storage
    if (timeDifference > 10) {
      console.log('INACTIVE: Clearing local storage...');
      localStorage.clear();
    }
  }

  let tempSelectedType = localStorage.getItem(SELECTEDTYPESTR)
  if (tempSelectedType == null) {
    tempSelectedType = ""
  }
  const [selectedType, setSelectedType] = useState(tempSelectedType);
  let tempPosts = localStorage.getItem(POSTSSTR)
  if (tempPosts == null) {
    tempPosts = []
  } else {
    try {
      tempPosts = JSON.parse(tempPosts)
    } catch (e) {
      tempPosts = []
    }
  }
  const [posts, setPosts] = useState(tempPosts);
  let tempScrollPosition = localStorage.getItem(SCROLLPOSITIONSTR)
  if (tempScrollPosition == null) {
    tempScrollPosition = 0
  }
  window.scrollTo(0, tempScrollPosition);

  const navigate = useNavigate();
  const selectedTypeRef = useRef(selectedType);
  const postRef = useRef(posts);
  const scrollPositionRef = useRef(tempScrollPosition);
  const lastVisitTimeRef = useRef(currentTime);

  useEffect(() => {
    postRef.current = posts;
  }, [posts]);

  const handlePostClick = (id) => {
    lastVisitTimeRef.current = new Date().getTime();
    navigate(`/article/${id}`, { state: { from: 'landingPage' } });
  };

  useEffect(() => {
    if (selectedType !== selectedTypeRef.current) {
      selectedTypeRef.current = selectedType;
      window.scrollTo(0, 93);
      localStorage.setItem(SCROLLPOSITIONSTR, scrollPositionRef.current);
      fetchPosts(selectedType);
    } else if (posts.length === 0) {
      fetchPosts(selectedTypeRef.current)
    }

  }, [selectedType]);

  // track the scroll position in scrollPositionRef
  useEffect(() => {
    const handleScroll = () => {
      scrollPositionRef.current = window.scrollY;
      lastVisitTimeRef.current = new Date().getTime();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  useEffect(() => {
    const oldSelectedType = localStorage.getItem(SELECTEDTYPESTR);
    if (oldSelectedType) {
      console.log("Setting to oldSelectedType" + oldSelectedType)
      setSelectedType(oldSelectedType)
    } else if (selectedType == "") {
      console.log("Setting to LATEST from EMPTY")
      setSelectedType(LATESTLATEST)
    } else {
      console.log("Setting to LATEST from undefined or null")
      setSelectedType(LATESTLATEST)
    }

    return () => {
      localStorage.setItem(LASTVISITTIMESTR, lastVisitTimeRef.current.toString());
      localStorage.setItem(SELECTEDTYPESTR, selectedTypeRef.current);
      localStorage.setItem(POSTSSTR, JSON.stringify(postRef.current));
      localStorage.setItem(SCROLLPOSITIONSTR, scrollPositionRef.current);
    };
  }, []);




  const setPostsFromArray = (type, data) => {
    console.log(data)
    const ids = data["ids"];
    const summaries = data["summaries"];
    const titles = data["titles"];
    const images = data["images"];
    const sources = data["sources"];
    const classification = data["classification"];
    console.log("class: " + classification)
    const newPosts = ids.map((id, index) => {
      return {
        id: ids[index],
        text: titles[index],
        summary: summaries[index],
        image: images[index][0],
        source: sources[index],
        // label: selectedTypeRef.current
        label: classification[index],

      };
    });
    setPosts(newPosts);
  }

  const fetchPosts = (type) => {
    const backend = "https://q98vfawf71.execute-api.us-east-1.amazonaws.com/Prod/hello?id=" + type
    fetch(backend, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.ids) {
          console.log("setting from fetch")
          setPostsFromArray(type, data);
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  };

  return (
    <div className="LandingPage" >
      <Header selectedType={selectedType} handleElementClick={handleTypeChange} />
      <Feed posts={posts} handlePostClick={handlePostClick} type={selectedTypeRef.current} />
    </div>
  );
}
export default LandingPage;