import React from 'react';
import './App.css';
import LandingPage from './LandingPage'
import Article from './Article';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {

  return (
    <div className="App">
      <link rel="stylesheet" href="https://use.typekit.net/mok3zrg.css"></link>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/article/:id" element={<Article />} />

          </Routes>
        </Router>
      </div>
  );
}

export default App;
