import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ArticleHeader from './ArticleHeader';
import './Article.css';
import Collapsible from 'react-collapsible';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";
import Frame from './Frame.js';
import NewscardRelated from './NewscardRelated.js';
const Article = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let { id } = useParams();
    const [article, setArticle] = useState({ title: "", category: "", imageaddy: "", date: "", article_json: [["", ""]], recommendation_list: [], facts: [] });
    const imageaddy = "";
    const category = "";
    const title = "";
    const date = "Published: ";
    const subheader = [];
    const paragraph = [];

    function backButton() {
        navigate(-1);
    }

    const [imageError, setImageError] = React.useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log("Setting article", article.recommendation_list)
        window.scrollTo(0, 0);
    }, [article]);


    function formatDate(dateStr) {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // Split the date string into parts
        const [year, month, day] = dateStr.split('-');

        // Remove leading zeros from day
        const dayWithoutLeadingZero = parseInt(day);

        // Get the month name
        const monthName = months[parseInt(month) - 1];

        // Return the formatted date
        return `${monthName} ${dayWithoutLeadingZero}, ${year}`;
    }

    function parseAndCleanData(data) {
        // Split the data string into parts
        console.log("Fact list: ", data)


        return data;
    }



    useEffect(() => {
        console.log("Location Article", location.state)
        const fetchArticle = async () => {
            try {
                const response = await fetch(`https://q98vfawf71.execute-api.us-east-1.amazonaws.com/Prod/article?id=${id}`);
                const data = await response.json();
                const title = data["title"] || "Title";
                const classification = data["classification"] || "Category";
                const img_urls = data["img_urls"] || [""];
                console.log("img_urls", img_urls)
                const articleDateStr = data["article_date"] || "0000-00-00";
                const articleDate = formatDate(articleDateStr)
                const article_json_obj = data["article_json"];
                const article_json = Object.values(article_json_obj) || [["", ""]];
                let arr_json = []
                for (let i = 0; i < article_json.length; i++) {
                    arr_json.push(Object.values(article_json[i]))
                }
                const recommendationList = data["recommendation_list"] || [];
                const factsStr = data["facts"];
                const facts = parseAndCleanData(factsStr) || [""];
                const newArticle = {
                    id: id,
                    title: title,
                    category: classification,
                    imageaddy: img_urls[0],
                    date: articleDate,
                    article_json: arr_json,
                    recommendation_list: recommendationList,
                    facts: facts
                }
                setArticle(newArticle);
                console.log("recommendations", recommendationList)

            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };
        fetchArticle();
    }, [id]);

    return (
        <div>
            <ArticleHeader />
            <div className="body-wrapper" style={{ paddingTop: "69px" }} id={id}>
                <div className="content-wrapper">
                    {/* <img className="hero-image-icon" alt="" src={article.imageaddy} /> */}
                    {article.imageaddy && !imageError ? (
                        <img
                            src={article.imageaddy}
                            className="hero-image-icon"
                            alt="Article"
                            id={article.imageaddy}
                            onError={() => setImageError(true)}
                        />

                    ) : (<div style={{ paddingTop: "5px" }}></div>)}
                    <div className="header-wrapper" id={article.title + article.id}>
                        <div className="category" id={article.category + article.id}>{article.category}</div>
                        <div className="headline" id={article.title + article.id}>{article.title}</div>
                        <div className="puplished-date-wrapper">
                            <div className="puplished-date" id={article.title + article.id}>{article.date}</div>
                        </div>
                    </div>
                    <div className="collapsing-list" id={article.id}>
                        <Frame factList={article.facts} />
                    </div>

                    <div className="article-container">
                        {/* Loop through article json tuple list, set first element to subheader and second to paragraph */}
                        {article.article_json.map((element, index) => (
                            <div>
                                <b className="h2" id={element[0] + article.id}>{element[0]}</b>
                                <div className="paragraph" id={"p" + article.id}>{element[1]}</div>
                            </div>
                        ))}

                    </div>
                    <div className="related-container">
                        <div className="frame">
                            <div className="header-related">Related</div>
                        </div>
                        <div className="card-container" >
                            {article.recommendation_list.map((element, index) => (
                                <NewscardRelated key={index} id={element.story_id + article.id} title={element.story_title} category={element.classification} img_url={element.story_img} story_id={element.story_id} />
                            ))}
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );
};


export default Article;

