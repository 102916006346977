import React from 'react';
import Svg from "./assets/LogoTemp.svg";
import Logo from "./assets/Logo_Circle_Newspaper_White.svg";
import BackbuttonSvg from "./assets/Backarrow.svg";
import './ArticleHeader.css';
import { useNavigate } from 'react-router-dom';
function ArticleHeader() {
    const navigate = useNavigate();
    function handleBack() {
        console.log("handle back button article")
        navigate(-1);
    }

    function handleHome() {
        console.log("handle home button article")
        navigate("/");
    }

    return (
        <div>
            <div className="header" style={{ boxSizing: "border-box" }} >
                <div className="column1" style={{ alignContent: "left" }} onClick={handleBack}>
                    <img className="backButton" id="logo" alt="" src={BackbuttonSvg} />
                </div>
                <div className="column2" style={{ alignContent: "center" }} onClick={handleHome}>
                    {/* <img className="logotemp1Icon" id="logo" alt="" src={Svg} /> */}
                    <img className="logotemp1Icon" id="logo" alt="" src={Logo} />
                </div>
                <div className="column3">

                </div>
            </div>
        </div>
    );
}

export default ArticleHeader;